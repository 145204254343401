import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import { JobVisitsSchedulerUtils } from 'common/Scheduler'
import { StyledWrapper } from '../../common/Scheduler/styledComponents'
import { Spinner } from 'common'
import { useEffect, useState } from 'react'
import { VisitForm } from 'components/Visits'
import { PlusIcon } from '@heroicons/react/24/solid'
import { DateSelectArg } from '@fullcalendar/core'
import { format } from 'date-fns';
import { JobsComponents } from 'components'

export function Scheduler() {
	const [openVisitForm, setOpenVisitForm] = useState(false)
	const [openDayWorksJobForm, setOpenDayWorksJobForm] = useState(false)
	const [selectedDate, setSelectedDate] = useState({
		start: '',
		team_leader: '',
	})

	useEffect(() => {
		if (!openVisitForm) {
			setSelectedDate({
				start: '',
				team_leader: '',
			})
		}
	}, [openVisitForm])

	const handleDateSelect = (selectInfo: DateSelectArg) => {
		setSelectedDate({
			start: selectInfo.startStr,
			team_leader: selectInfo.resource ? selectInfo.resource.id : '',
		})
		setOpenVisitForm(true)
	}

	const {
		visitsLoading,
		headerToolbar,
		eventContent,
		eventDrop,
		formatEvents,
	} = JobVisitsSchedulerUtils()

	if (visitsLoading) {
		return <Spinner />
	}
	return (
		<StyledWrapper>
			<div className="flex justify-end mb-6">
				<button
					type="button"
					className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
					onClick={() => setOpenVisitForm(true)}>
					<PlusIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
					Schedule Visit
				</button>
				<button
					type="button"
					className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
					onClick={() => setOpenDayWorksJobForm(true)}>
					<PlusIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
					Create Job
				</button>
			</div>
			<FullCalendar
				headerToolbar={{
					...headerToolbar,
					center: 'Day,Week,Month',
				}}
				views={{
					Month: {
						type: 'dayGridMonth',
						slotLabelInterval: { days: 1 },
						weekends: true,
						eventContent, 
					},
					Week: {
						type: 'dayGridWeek',
						slotLabelInterval: { days: 1 },
						weekends: true,
						eventContent,
						dayHeaderContent: (args) => {
							const formattedDate = format(args.date, 'dd/MM');
							return <span>{formattedDate}</span>;
						}
					},
					Day: {
						type: 'dayGridDay',
						slotLabelInterval: { hours: 1 },
						dayHeaderContent: (args) => {
							const formattedDate = format(args.date, 'dd/MM');
							return <span>{formattedDate}</span>;
						},
						weekends: true,
						eventContent,
					},
				}}
				eventContent={eventContent}
				plugins={[
					dayGridPlugin,
					timeGridPlugin,
					interactionPlugin,
					resourceTimelinePlugin,
				]}
				buttonText={{
					today:    'Today',
					month:    'Month',
					week:     'Week',
					day:      'Day',
					list:     'List',
				  }}
				initialView="Week"
				height="auto"
				editable
				selectable
				dayMaxEvents
				firstDay={1}
				events={formatEvents()}
				eventDrop={eventDrop}
				schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
				select={handleDateSelect}
			/>
			<VisitForm
				heading="Schedule Visit"
				setOpen={setOpenVisitForm}
				formType="create"
				open={openVisitForm}
				startDate={selectedDate.start}
				teamLeader={selectedDate.team_leader}
			/>
			<JobsComponents.DayWorksForm
				formType="create"
				heading="Create Job"
				open={openDayWorksJobForm}
				setOpen={setOpenDayWorksJobForm}
			/>
		</StyledWrapper>
	)
}
