import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQueryClient } from '@tanstack/react-query'

export interface ICreateTimesheetPayload {
	date: string
	job_id: number | null
	time_on: string
	time_off: string
	hours: number
	comments: string
	status: string
	staff_ids: number[]
	timesheet_id: string
	approved_by: string
	actual_start: string
	actual_finish: string
	exported: string
	visitTimeSheetId: string
	lunch_break: number
}

export const useCreateTimesheet = () => {
	const { showError, showSuccess } = useContext(NotificationsContext)
	const { putRequest } = useApi()
	const queryClient = useQueryClient()

	const createTimesheet = async (data: ICreateTimesheetPayload) => {
		try {
			await putRequest(AppRoutes.serverTimesheetRoutes.createTimesheet, data)
			showSuccess('Timesheet created successfully')
			queryClient.refetchQueries(['timesheets'])
		} catch (error) {
			showError('Something went wrong when creating the timesheet')
			throw new Error('Something went wrong creating the Timesheet')
		}
	}

	return { createTimesheet }
}
