import { CreateFile, DateSelect, Input, TextArea } from 'common'
import { useFormik } from 'formik'
import { VehicleServices } from 'services'
import { SideModal, Spinner } from 'common'
import * as Yup from 'yup'
import { useEffect } from 'react'

interface IProps {
	ruc_id?: number
	vehicle_id?: number
	heading: string
	setOpen: (open: boolean) => void
	formType: 'create' | 'update'
	open: boolean
	start?: number
}

interface IInitialValues {
	vehicle_id: string
	start: number
	end: number
	date_brought: string
	units: number
	cost: number
}

export const VehicleRUCForm = ({
	ruc_id,
	vehicle_id,
	heading,
	setOpen,
	formType,
	open,
	start,
}: IProps) => {
	const { createVehicleRUC } = VehicleServices.useCreateVehicleRUC()
	const { updateVehicleRUC } = VehicleServices.useUpdateVehicleRUC()
	const { data, isLoading } = VehicleServices.useVehicleRUCById(ruc_id)

	const initialValues: IInitialValues = {
		vehicle_id: data?.vehicle_id || vehicle_id,
		start: data?.start || start,
		end: data?.end || 0,
		date_brought: data?.date_brought || '',
		units: data?.units || 5,
		cost: data?.cost || 0,
	}

	const validationSchema = Yup.object({
		start: Yup.number().required('The RUC Start is required'),
		date_brought: Yup.string().required('The Date Brought is required'),
		units: Yup.number().required('The Date Brought is required'),
		// cost: Yup.string().required('The Cost is required'),
	})

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true)

			if (formType === 'create') {
				const vehicleRUCData = {
					vehicle_id: values.vehicle_id,
					start: values.start,
					end: values.end,
					date_brought: values.date_brought,
					units: values.units,
					cost: values.cost,
				}
				await createVehicleRUC(vehicleRUCData)
			} else {
				const vehicleRUCData = {
					vehicle_id: values.vehicle_id,
					start: values.start,
					end: values.end,
					date_brought: values.date_brought,
					units: values.units,
					cost: values.cost,
				}
				await updateVehicleRUC(Number(ruc_id), vehicleRUCData)
			}

			setSubmitting(false)
			formik.resetForm()
			setOpen(false)
		},
	})

	useEffect(() => {
		if (!open) {
			formik.resetForm()
		}
	}, [open])

	useEffect(() => {
		if (formik.values.start && formik.values.units) {
			formik.setFieldValue(
				'end',
				Number(formik.values.start) + Number(formik.values.units) * 1000
			)
		}
	}, [formik.values.start, formik.values.units])

	if (vehicle_id && isLoading) {
		return <Spinner />
	}

	return (
		<>
			<SideModal
				heading={heading}
				open={open}
				setOpen={setOpen}
				handleSubmit={formik.handleSubmit}
				isLoading={formik.isSubmitting}
				formType={formType}>
				<div>
					<div className="flex items-center px-2">
						<div className="w-1/2">
							<DateSelect
								id="date_brought"
								title="Date Brought"
								value={formik.values.date_brought}
								onChange={formik.setFieldValue}
								error={formik.errors.date_brought}
							/>
						</div>
					</div>
					<div className="flex items-center px-2">
						<div className="w-1/2">
							<Input
								title="Start"
								placeholder="Start"
								id="start"
								type="text"
								error={formik.errors.start}
								value={formik.values.start}
								handleChange={formik.handleChange}
								handleBlur={formik.handleBlur}
							/>
						</div>
						<div className="w-1/2">
							<Input
								title="End"
								placeholder="End"
								id="end"
								type="text"
								error={formik.errors.end}
								value={formik.values.end}
								handleChange={formik.handleChange}
								handleBlur={formik.handleBlur}
							/>
						</div>
					</div>
					<div className="flex items-center px-2">
						<div className="w-1/2">
							<Input
								title="Units"
								placeholder="Units"
								id="units"
								type="text"
								error={formik.errors.units}
								value={formik.values.units}
								handleChange={formik.handleChange}
								handleBlur={formik.handleBlur}
							/>
						</div>
						<div className="w-1/2">
							<Input
								title="Cost"
								placeholder="Cost"
								id="cost"
								type="text"
								error={formik.errors.cost}
								value={formik.values.cost}
								handleChange={formik.handleChange}
								handleBlur={formik.handleBlur}
							/>
						</div>
					</div>
					{/* <div className="flex items-center px-2">
						<TextArea
							title="Extra Work Done"
							id="extra_work_done"
							type="text"
							error={formik.errors.extra_work_done}
							value={formik.values.extra_work_done}
							handleChange={formik.handleChange}
							handleBlur={formik.handleBlur}
						/>
					</div>
					<div className="w-1/2 pl-2">
						<CreateFile
							field="file"
							setFieldValue={formik.setFieldValue}
							value={formik.values.file}
						/>
					</div> */}
				</div>
			</SideModal>
		</>
	)
}
