import { useEffect, useState } from 'react'
import { Dropdown } from 'primereact/dropdown'
import { OptionsForDropdown, numberFormat } from 'utilities'
import {
	IQuoteLine,
	IQuoteZones,
	IRates,
	IQuoteLinesHandlers,
} from 'models/simple_quotes.model'
import { XCircleIcon } from '@heroicons/react/24/outline'
import { Button } from 'primereact/button'
import { ConfirmationDialog, RadioButton } from 'common'
import { quoteLinesFn } from 'services/simple_quotes'

interface QuoteLineProps {
	zones: IQuoteZones[]
	rates: IRates[]
	data: IQuoteLine
	quoteLinesHandlers: IQuoteLinesHandlers
	index: number
}

export const SimpleQuoteLine = ({
	zones,
	rates,
	data,
	index,
	quoteLinesHandlers,
}: QuoteLineProps) => {
	const [dirtyFields, setDirtyFields] = useState({
		labour: false,
		transport: false,
		scrim: false,
		shrinkwrap: false,
		hire: false,
	})

	const handleProductLineChange = (
		index: number,
		field: keyof IQuoteLine,
		value: string | number | boolean
	) => {
		quoteLinesHandlers.updateQuoteLine(index, field, value)
	}

	return (
		<tr>
			<td className="text-sm bg-white border border-gray-100 whitespace-nowrap w-10">
				<div className="flex justify-center">
					<RadioButton
						id={`calculation-${index}`}
						options={[
							{ value: 'm2', label: 'M2' },
							{ value: 'm3', label: 'M3' },
						]}
						value={data.calculation}
						onChange={(id: any, value: string) => {
							handleProductLineChange(index, 'calculation', value)
						}}
						orientation="horizontal"
					/>
				</div>
			</td>
			<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-10">
				<Dropdown
					title="zone_id"
					options={OptionsForDropdown(zones, 'zone_id', 'zone_label')}
					id={`zone_id-${index}`}
					value={data.zone_id}
					onChange={(e) =>
						handleProductLineChange(index, 'zone_id', e.target.value)
					}
					className="w-full h-8 text-gray-700 bg-white border border-gray-200 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
				/>
			</td>
			{/* <td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-10">
				<Dropdown
					title="Rate"
					options={OptionsForDropdown(rates, 'service', 'service')}
					id={`type-${index}`}
					value={data.type}
					onChange={(e) =>
						handleProductLineChange(index, 'type', e.target.value)
					}
					className="w-full h-8 text-gray-700 bg-white border border-gray-200 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
				/>
			</td> */}
			<td className="px-1 py-1 h-8 text-sm bg-white border border-gray-100 whitespace-nowrap w-48">
				<input
					id={`description-${index}`}
					type="text"
					className="h-8 px-2 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none text-center"
					value={data.description}
					onChange={(e) =>
						handleProductLineChange(index, 'description', e.target.value)
					}
				/>
			</td>
			{/* <td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-24">
				<input
					id={`length-${index}`}
					type="number"
					className="h-8 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none text-center"
					value={data.length}
					onChange={(e) =>
						handleProductLineChange(index, 'length', Number(e.target.value))
					}
					min={1}
				/>
			</td>
			<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-24">
				<input
					id={`height-${index}`}
					type="number"
					className="h-8 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none text-center"
					value={data.height}
					onChange={(e) =>
						handleProductLineChange(index, 'height', Number(e.target.value))
					}
					disabled={data.type === 'Brick Guards'}
					min={1}
				/>
			</td>
			<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-24">
				<input
					id={`width-${index}`}
					type="number"
					className="h-8 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none text-center"
					value={data.width}
					onChange={(e) =>
						handleProductLineChange(index, 'width', Number(e.target.value))
					}
					disabled={data.type === 'Brick Guards'}
					min={1}
				/>
			</td> */}
			<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-24">
				<input
					id={`sqm-${index}`}
					type="number"
					className="h-8 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none text-center"
					value={data.meters}
					onChange={(e) =>
						handleProductLineChange(index, 'meters', Number(e.target.value))
					}
					disabled
				/>
			</td>
			<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
				<input
					id={`labour-${index}`}
					type="number"
					className="h-8 px-2 text-sm rounded-md w-full border border-gray-300 text-gray-600 focus:outline-none text-center"
					style={{
						background:
							data.labour_is_dirty || dirtyFields.labour ? '#fff2e2' : '',
					}}
					value={Number(data.labour)}
					onChange={(e) => {
						handleProductLineChange(
							index,
							'labour',
							parseFloat(e.target.value.replace(/[^\d.-]/g, '')) || 0
						)
						setDirtyFields({ ...dirtyFields, labour: true })
					}}
				/>
			</td>
			<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
				<input
					id={`transport-${index}`}
					type="number"
					className="h-8 px-2 text-sm rounded-md w-full border border-gray-300 text-gray-600 focus:outline-none text-center"
					style={{
						background:
							data.transport_is_dirty || dirtyFields.transport ? '#fff2e2' : '',
					}}
					value={Number(data.transport)}
					onChange={(e) => {
						handleProductLineChange(
							index,
							'transport',
							parseFloat(e.target.value.replace(/[^\d.-]/g, '')) || 0
						)
						setDirtyFields({ ...dirtyFields, transport: true })
					}}
				/>
			</td>
			<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
				<input
					id={`scrim-${index}`}
					type="number"
					className="h-8 px-2 text-sm rounded-md w-full border border-gray-300 text-gray-600 focus:outline-none text-center"
					style={{
						background:
							data.scrim_is_dirty || dirtyFields.scrim ? '#fff2e2' : '',
					}}
					value={Number(data.scrim)}
					onChange={(e) => {
						handleProductLineChange(
							index,
							'scrim',
							parseFloat(e.target.value.replace(/[^\d.-]/g, '')) || 0
						)
						setDirtyFields({ ...dirtyFields, scrim: true })
					}}
				/>
			</td>
			<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
				<input
					id={`shrinkwrap-${index}`}
					type="number"
					className="h-8 px-2 text-sm rounded-md w-full border border-gray-300 text-gray-600 focus:outline-none text-center"
					style={{
						background:
							data.shrinkwrap_is_dirty || dirtyFields.shrinkwrap
								? '#fff2e2'
								: '',
					}}
					value={Number(data.shrinkwrap)}
					onChange={(e) => {
						handleProductLineChange(
							index,
							'shrinkwrap',
							parseFloat(e.target.value.replace(/[^\d.-]/g, '')) || 0
						)
						setDirtyFields({ ...dirtyFields, shrinkwrap: true })
					}}
				/>
			</td>
			<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
				<input
					id={`hire-${index}`}
					type="number"
					className="h-8 px-2 text-sm rounded-md w-full border border-gray-300 text-gray-600 focus:outline-none text-center"
					style={{
						background: data.hire_is_dirty || dirtyFields.hire ? '#fff2e2' : '',
					}}
					value={data.hire}
					onChange={(e) => {
						handleProductLineChange(index, 'hire', Number(e.target.value))
						setDirtyFields({ ...dirtyFields, hire: true })
					}}
				/>
			</td>
			<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
				<input
					id={`hire_duration-${index}`}
					type="number"
					className="h-8 px-2 text-sm rounded-md w-full border border-gray-300 text-gray-600 focus:outline-none text-center"
					value={data.hire_duration}
					onChange={(e) => {
						handleProductLineChange(
							index,
							'hire_duration',
							Number(e.target.value)
						)
					}}
				/>
			</td>
			<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
				<input
					id={`total-${index}`}
					type="text"
					className="h-8 px-2 text-sm rounded-md w-full border border-gray-300 text-gray-500 focus:outline-none"
					value={numberFormat.format(Number(data.total))}
					defaultValue={numberFormat.format(Number(data.total))}
					disabled
				/>
			</td>
			<td className="px-1 py-1 text-tiny bg-white border border-gray-100 whitespace-nowrap w-8">
				<ConfirmationDialog
					icon="danger"
					title="Delete Line Item"
					body="Are you sure you want to delete this item? This action is unrecoverable!"
					triggerButton={
						<button type="button">
							<XCircleIcon
								className="flex-shrink-0 h-4 w-4 text-red-500"
								aria-hidden="true"
							/>
						</button>
					}
					confirmButton={
						<Button
							className="bg-red-600 text-white"
							onClick={async () => quoteLinesHandlers.removeQuoteLine(index)}>
							Delete Line
						</Button>
					}
				/>
			</td>
		</tr>
	)
}
