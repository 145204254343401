import { useEffect, useState } from 'react'
import { DataTable } from 'primereact/datatable'
import {
	TimesheetServices,
	DataTableServices,
	VisitTimesheetsServices,
	StaffServices,
	VisitServices,
	JobsServices,
} from 'services'
import { Badge, Container, DataTableHeader, PageHeading, Spinner } from 'common'
import { Column } from 'primereact/column'
import moment from 'moment'
import { ApproveTimesheet } from 'components/Timesheets'
import { FilterMatchMode } from 'primereact/api'
import { tableFilterMatchModeOptions } from 'services/DataTable'
import { EditTimesheet } from 'components/Timesheets/EditForm'
import { VisitTimesheetForm } from 'components/VisitTimesheets'
import { PencilIcon } from '@heroicons/react/24/outline'
import { ColumnGroup } from 'primereact/columngroup'
import { Row } from 'primereact/row'
import { CheckIcon } from '@heroicons/react/24/solid'

export const VisitsBasedTimesheetsPage = () => {
	const { data: timesheetData, isLoading: timesheetLoading } =
		VisitTimesheetsServices.useVisitTimesheets()
	const { data, isLoading } = TimesheetServices.useTimesheetsByStatus('Pending')
	const { updateVisitTimesheet } = VisitTimesheetsServices.useUpdateTimesheetById()
	const [openVisitTimesheetForm, setOpenVisitTimesheetForm] = useState(false)
	const [timesheetId, setTimesheetId] = useState(undefined)
	const [timesheetsSelected, setTimesheetsSelected] = useState([])
	const [generatedData, setGeneratedData] = useState<any>([])

	useEffect(() => {
		if (!timesheetLoading && !isLoading) {
			const findStartTime = (staffId: number, date: string) => {
				const entry = data.find(
					(item: { staff_id: number; date: string }) =>
						item.staff_id === staffId && item.date === date
				)
				return entry ? entry.time_on : null
			}

			const sortAndGroupEntries = (data: any) => {
				const groupedEntries: any = {}

				data.forEach((item: { staff: { id: any }; date: any }) => {
					const key = `${item.staff.id}_${item.date}`
					if (!groupedEntries[key]) {
						groupedEntries[key] = []
					}
					groupedEntries[key].push(item)
				})

				Object.keys(groupedEntries).forEach((key) => {
					groupedEntries[key].sort(
						(
							a: { time_in: moment.MomentInput },
							b: { time_in: moment.MomentInput }
						) => {
							const aTimeIn = moment(a.time_in, 'HH:mm').valueOf()
							const bTimeIn = moment(b.time_in, 'HH:mm').valueOf()
							return aTimeIn - bTimeIn
						}
					)
				})

				return groupedEntries
			}

			const updateStartTimeForMultipleEntries = (groupedEntries: any) => {
				Object.keys(groupedEntries).forEach((key) => {
					const entries = groupedEntries[key]
					for (let i = 0; i < entries.length; i++) {
						if (!entries[i].job_start_time) {
							if (i === 0) {
								const lookedUpStartTime = findStartTime(
									entries[i].staff.id,
									entries[i].date
								)
								entries[i].job_start_time =
									lookedUpStartTime || entries[i].time_in
							} else {
								entries[i].job_start_time = entries[i - 1].time_off
							}
						}
					}
				})

				return Object.values(groupedEntries).flat()
			}

			const groupedEntries = sortAndGroupEntries(timesheetData)

			const updatedTimesheetData: any =
				updateStartTimeForMultipleEntries(groupedEntries)

			const rowData = updatedTimesheetData
				?.filter(
					(timesheet: { job: { status: string } }) =>
						timesheet?.job?.status === 'Active' ||
						timesheet?.job?.status === 'Approved'
				)
				?.map((timesheet: any) => {
					const visit: any = timesheet.visit
					const job: any = timesheet?.job
					const staff: any = timesheet?.staff

					const start_time =
						moment(timesheet.job_start_time, 'HH:mm') ||
						moment(timesheet.time_in, 'HH:mm')
					const end_time = moment(timesheet.time_off, 'HH:mm')
					const difference = end_time.diff(start_time, 'hours', true) || 0

					return {
						record_id: timesheet?.id,
						staff_id: staff?.id,
						id:
							String(job?.id) +
							'_' +
							String(visit?.id) +
							'_' +
							String(staff?.id) +
							'_' +
							String(timesheet?.id),
						visit_id: visit?.id,
						name: staff?.staff_name,
						job_start_time: timesheet.job_start_time || timesheet?.time_in,
						pre_start_time: timesheet?.time_in,
						end_time: timesheet?.time_off,
						date: timesheet?.date,
						job: job?.job_num,
						description: job?.descriptionOfQuote,
						client_name: job?.clientData?.client_name,
						site: job?.site,
						hours: difference,
						confined_spaces: job?.job_type === 'Confined Spaces' ? true : false,
						timesheet_status: timesheet?.status,
						timestamp: timesheet.createdAt
							? moment(timesheet.createdAt).toDate().getTime()
							: -1,
					}
				})

			setGeneratedData(rowData)
		}
	}, [timesheetData, timesheetLoading, data, isLoading])

	const {
		clearFilter,
		filters,
		globalFilterValue,
		setFilters,
		setGlobalFilterValue,
		globalFilterFields,
		dataTableReference,
	} = DataTableServices.useFiltersDataTable({
		initialFilters: [],
		additionalGlobalFilterFields: [
			'name',
			'job',
			'site',
			'visit_id',
			'date',
			'status',
		],
	})

	const calculateTimesheetTotal = (row: {
		staff_id: number
		comments: string
		visit_id: any
	}) => {
		let total = 0

		if (generatedData) {
			for (const timesheet of generatedData) {
				if (
					Number(timesheet.visit_id) === Number(row.visit_id) &&
					timesheet.status !== 'Pending' &&
					Number(timesheet.hours) >= 0
				) {
					const hours = Number(timesheet.hours)
					total += hours
				}
			}
		}
		return total
	}

	const headerTemplate = (data: { visit_id: number; date: string }) =>
		data.visit_id ? (
			<span className="text-gray-900 font-bold">
				Visit #{data?.visit_id} on {data?.date}
			</span>
		) : (
			<span className="text-gray-900 font-bold">No Visits</span>
		)

	const footerTemplate = (data: any) =>
		data.visit_id ? (
			<>
				<td
					colSpan={8}
					style={{ textAlign: 'right' }}
					className="bg-gray-100 font-normal">
					Total Hours
				</td>
				<td colSpan={1} className="bg-gray-100 font-semibold">
					{calculateTimesheetTotal(data)}
				</td>
			</>
		) : (
			<></>
		)

	const header = DataTableHeader({
		clearFilter,
		globalFilterValue,
		filters,
		setFilters,
		setGlobalFilterValue,
		dataTableReference,
	})

	useEffect(() => {
		if (!openVisitTimesheetForm) {
			setTimesheetId(undefined)
		}
	}, [openVisitTimesheetForm])

	const isSelectable = (data: { end_time: string }) => {
		return data?.end_time?.length >= 0
	}

	const isRowSelectable = (event: any) =>
		event.data ? isSelectable(event.data) : true

	if (timesheetLoading || isLoading) {
		return <Spinner />
	}

	return (
		<div className="card">
			<PageHeading
				title="Visit Timesheets"
				createBtn={'Create Visit Timesheet'}
				isEditable={false}
				setOpen={setOpenVisitTimesheetForm}
			/>
			<Container>
				<DataTable
					ref={dataTableReference}
					value={generatedData}
					loading={timesheetLoading}
					paginator
					showGridlines
					rows={100}
					paginatorPosition="top"
					groupRowsBy="visit_id"
					dataKey="id"
					filters={filters}
					globalFilterFields={globalFilterFields}
					rowGroupMode="subheader"
					rowGroupHeaderTemplate={headerTemplate}
					rowGroupFooterTemplate={footerTemplate}
					rowsPerPageOptions={[25, 50, 100]}
					sortOrder={-1}
					sortField='timestamp'
					header={header}
					resizableColumns={true}
					selection={timesheetsSelected}
					isDataSelectable={isRowSelectable}
					stripedRows
					onSelectionChange={(e) => setTimesheetsSelected(e.value as any)}
					emptyMessage="No Timesheets found.">
					<Column
						selectionMode="multiple"
						headerStyle={{
							width: '3rem',
							display: 'flex',
							alignItems: 'center',
							paddingTop: '0.5rem',
							marginTop: '-0.5px',
							paddingBottom: '0.5rem',
							marginBottom: '-0.5px',
							marginLeft: '-0.5px',
							justifyContent: 'center',
						}}
						bodyClassName={'text-sm'}
						bodyStyle={{
							alignItems: 'center',
							justifyContent: 'center',
						}}
					/>
					<Column field="name" header="Staff" style={{ minWidth: '5rem' }} bodyClassName={'text-sm'}/>
					<Column
						field="visit_id"
						header="Visit #"
						style={{ minWidth: '2rem' }} bodyClassName={'text-sm'}
					/>
					<Column field="job" header="Job #" style={{ minWidth: '2rem' }} bodyClassName={'text-sm'} />
					<Column
						field="client_name"
						header="Client"
						style={{ minWidth: '2rem', maxWidth: '10rem' }} bodyClassName={'text-sm'}
					/>
					<Column
						field="description"
						header="Description"
						style={{ minWidth: '2rem', maxWidth: '24em' }} bodyClassName={'text-sm'}
					/>

					{/* <Column
						field="site"
						header="Site"
						style={{ minWidth: '5rem' }}
					/> */}
					<Column header="Date" field="date" style={{ minWidth: '5rem' }} bodyClassName={'text-sm'} />
					<Column
						header="Job Start"
						field="job_start_time"
						style={{ minWidth: '5rem' }} bodyClassName={'text-sm'}
					/>
					<Column
						header="Pre Start"
						field="pre_start_time"
						style={{ minWidth: '5rem' }} bodyClassName={'text-sm'}
					/>
					<Column
						header="Time Off"
						field="end_time"
						style={{ minWidth: '5rem' }} bodyClassName={'text-sm'}
					/>
					<Column
						header="Hours"
						field="hours"
						showFilterMatchModes={false}
						style={{ minWidth: '4rem' }} bodyClassName={'text-sm'}
					/>
					<Column
						header="Timesheet Status"
						field="timesheet_status"
						bodyClassName="p-text-center text-sm"
						style={{ maxWidth: '8rem' }}
						body={(row) => (
							<Badge type={row.timesheet_status} text={row.timesheet_status} />
						)}
					/>
					<Column
						header="Confined Spaces?"
						field="confined_spaces"
						bodyClassName="p-text-center text-sm"
						style={{ maxWidth: '8rem' }}
						body={(row) =>
							row.confined_spaces ? (
								<Badge type={'Warning'} text={'Confined Space'} />
							) : (
								<Badge type={'Green'} text={'No'} />
							)
						}
					/>
					<Column
						field="details"
						header="Operations"
						style={{ width: '7rem' }}
						bodyClassName="p-text-center text-sm"
						body={(rowData) => (
							<div className="flex-row space-y-2 mt-2 mb-2 items-start mr-4">
								<div className="flex items-center">
									<button
										onClick={async () => {
											const timeSheet = timesheetData.find(
												(timeSheet: { id: any }) => timeSheet.id === rowData.record_id
											)
											await updateVisitTimesheet({...timeSheet, status: 'Approved'})
										}}
										className="flex items-center text-green-400">
										<CheckIcon className="h-4 w-4 mx-2" />
										<span>Mark Approved</span>
									</button>
								</div>
								<div className="flex items-center">
									<button
										onClick={() => {
											setTimesheetId(rowData.record_id)
											setOpenVisitTimesheetForm(true)
										}}
										className="flex items-center">
										<PencilIcon className="h-4 w-4 mx-2" />
										<span>Edit</span>
									</button>
								</div>
							</div>
						)}
					/>
				</DataTable>
			</Container>
			<VisitTimesheetForm
				timesheet_id={timesheetId}
				heading="Create Visit Timesheet"
				setOpen={setOpenVisitTimesheetForm}
				open={openVisitTimesheetForm}
			/>
		</div>
	)
}
