import { useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { AppFilesComponents } from 'components'
import { TwoColumnDetails, Section } from 'common/Details'
import { Tabs, Spinner, ErrorComponent } from 'common'
import { VehicleServices } from 'services'
import { AppRoutes } from 'config'
import { Notes } from 'components/Notes'
import ServiceTable from 'components/Vehicles/services/ServiceTable'
import RUCTable from 'components/Vehicles/RUCs/RUCTable'
import { numberFormat } from 'utilities'

export const DetailsPage = () => {
	const [tabIndex, setTabIndex] = useState(0)

	const { id } = useParams()
	const location = useLocation()

	const {
		data: vehicleData,
		error: vehicleError,
		isLoading: vehicleLoading,
	} = VehicleServices.useVehicleById(id)
	const {
		data: vehicleServiceData,
		error: vehicleServiceError,
		isLoading: vehicleServiceLoading,
	} = VehicleServices.useVehicleServicesByVehicleId(id)
	const {
		data: vehicleRUCData,
		error: vehicleRUCError,
		isLoading: vehicleRUCLoading,
	} = VehicleServices.useVehicleRUCsByVehicleId(id)

	const items = [
		{ label: 'App Files', id: 0 },
		{ label: 'Admin Files', id: 1 },
		{ label: 'Costs', id: 2 },
		{ label: 'RUCs', id: 3 },
	]

	// if (vehicleLoading || vehicleServiceLoading || vehicleRUCLoading) {
	// 	return (
	// 		<div className="w-full h-48 flex justify-center items-center">
	// 			<Spinner />
	// 		</div>
	// 	)
	// }

	if (vehicleError || vehicleServiceError || vehicleRUCError) {
		return <ErrorComponent />
	}

	const preprocessedVehicleData = useMemo(() => {
		if (vehicleData && vehicleServiceData && vehicleRUCData) {
			const totalSpentOnTruck = vehicleServiceData
				.map((service: any) => Number(service.cost))
				.reduce((acc: any, curr: any) => acc + curr, 0)

			const totalRUCCosts = vehicleRUCData
				.map((ruc: any) => Number(ruc.cost))
				.reduce((acc: any, curr: any) => acc + curr, 0)

			return {
				...vehicleData,
				totalSpentOnTruck,
				totalRUCCosts,
				total: totalSpentOnTruck + totalRUCCosts,
			}
		}
		return []
	}, [vehicleData, vehicleServiceData, vehicleRUCData])

	return (
		<div className="w-full mx-auto mt-8 mb-28">
			{preprocessedVehicleData && (
				<TwoColumnDetails
					heading="Vehicle Details"
					editBtn="Edit Vehicles"
					editLink={{
						to: AppRoutes.privateRoutes.VehiclesEdit.replace(':id', id || ''),
						state: { background: location, name: 'editVehicle' },
					}}>
					<Section
						title="Vehicle Rego"
						content={preprocessedVehicleData.Rego}
					/>
					<Section title="Make" content={preprocessedVehicleData.Make} />
					<Section title="Model" content={preprocessedVehicleData.Model} />
					<Section title="Rego Due" content={preprocessedVehicleData.RegoDue} />
					<Section title="WOF Due" content={preprocessedVehicleData.WOFDate} />
					<Section
						title="Service Due Date"
						content={preprocessedVehicleData.ServiceDueDate}
					/>
					<Section
						title="Service Due Km"
						content={preprocessedVehicleData.ServiceDueKm}
					/>
					<Section
						title="Odometer"
						content={preprocessedVehicleData.Odometer}
					/>
					<Section
						title="Hubometer"
						content={preprocessedVehicleData.Hubometer}
					/>
					<Section
						title="Location"
						content={preprocessedVehicleData.location}
					/>
					<Section
						title="Assigned to"
						content={preprocessedVehicleData?.staff?.staff_name}
					/>
					<Section title="" content={''} />
					<Section
						title="Total spent on truck to date"
						content={numberFormat.format(
							preprocessedVehicleData?.totalSpentOnTruck || 0
						)}
					/>
					<Section
						title="Total RUC Costs to date"
						content={numberFormat.format(
							preprocessedVehicleData?.totalRUCCosts || 0
						)}
					/>
					<Section
						title="Total"
						content={numberFormat.format(preprocessedVehicleData?.total || 0)}
					/>
					<div />
				</TwoColumnDetails>
			)}
			<div className="px-8">
				<Tabs tabIndex={tabIndex} setTabIndex={setTabIndex} tabs={items} />
			</div>
			{tabIndex === 0 && (
				<AppFilesComponents.FileList type="vehicles" id={id || ''} />
			)}
			{tabIndex === 1 && <Notes type="vehicle" id={id || ''} />}
			{tabIndex === 2 && <ServiceTable vehicle_id={Number(id)} />}
			{tabIndex === 3 && <RUCTable vehicle_id={Number(id)} />}
		</div>
	)
}
