import { Text, View } from '@react-pdf/renderer'
import { numberFormat } from 'utilities'
import { QuoteLinesFoorter as styles } from './styles'
import { IQuoteLine } from 'models'

interface FooterProps {
	items: IQuoteLine[]
}

export const FooterQuoteLines = ({ items }: FooterProps) => {
	// Calculate totals
	const labour = items
		.map((item) => Number(item.labour))
		.reduce((accumulator, currentValue) => accumulator + currentValue, 0)

	const transport = items
		.map((item) => Number(item.transport))
		.reduce((accumulator, currentValue) => accumulator + currentValue, 0)

	const scrim = items
		.map((item) => Number(item.scrim))
		.reduce((accumulator, currentValue) => accumulator + currentValue, 0)

	const hire = items
		.map((item) => Number(item.hire))
		.reduce((accumulator, currentValue) => accumulator + currentValue, 0)

	return (
		<View style={styles.row}>
			<Text style={styles.description}>Total</Text>
			<Text style={styles.erectTotal}>{numberFormat.format(labour)}</Text>
			<Text style={styles.erectTotal}>{numberFormat.format(transport)}</Text>
			<Text style={styles.erectTotal}>{numberFormat.format(scrim)}</Text>
			<Text style={styles.weeklyTotal}>{numberFormat.format(hire)}</Text>
		</View>
	)
}
