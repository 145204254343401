import { useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { TwoColumnDetails, Section } from 'common/Details'
import { Tabs, Spinner, ErrorComponent, Table, Badge } from 'common'
import {
	StaffServices,
	TimesheetServices,
	VisitTimesheetsServices,
} from 'services'
import { AppRoutes } from 'config'
import { Notes } from 'components/Notes'
import { time } from 'console'
import moment from 'moment'
import { CheckIcon } from '@heroicons/react/24/solid'
import { ColumnGroup } from 'primereact/columngroup'
import { Row } from 'primereact/row'
import { Column } from 'primereact/column'

export const TimesheetDetails = () => {
	const [tabIndex, setTabIndex] = useState(0)

	const { id } = useParams()
	const location = useLocation()
	const {
		data: timesheetData,
		isLoading: timesheetLoading,
		error: timesheetError,
	} = TimesheetServices.useTimesheetById(id)
	const { updateVisitTimesheet } = VisitTimesheetsServices.useUpdateTimesheetById()

	// Visit Timesheets for this date and this staffID

	// const { data: staffData, isLoading: staffLoading, error: staffError } = StaffServices.useStaffById(timesheetData?.staff_id)
	const {
		data: visitTimesheets,
		isLoading: visitTimesheetsLoading,
		error: visitTimesheetsError,
	} = VisitTimesheetsServices.useVisitTimesheets()

	if (timesheetLoading || visitTimesheetsLoading) {
		return (
			<div className="w-full h-48 flex justify-center items-center">
				<Spinner />
			</div>
		)
	}

	if (timesheetError) {
		return <ErrorComponent />
	}

	const filteredVisitTimesheets = visitTimesheets
		?.filter((visitTimesheet: any) => {
			if (
				String(visitTimesheet?.staff_id) === String(timesheetData?.staff_id) &&
				visitTimesheet.date === timesheetData?.date
			) {
				return visitTimesheet
			}
		})

	const mappedVisitTimesheets = filteredVisitTimesheets
		.map((visitTimesheet: any, index: number) => {
			
			if (!visitTimesheet.job_start_time) {
				if (index === 0) {
					visitTimesheet.job_start_time = timesheetData.time_on || visitTimesheet.time_in
				} else {
					visitTimesheet.job_start_time = filteredVisitTimesheets[index - 1].time_off;
				}
			}
			return { ...visitTimesheet }
		})

	if (mappedVisitTimesheets.length > 0 && timesheetData.time_off) {
		mappedVisitTimesheets[mappedVisitTimesheets.length - 1].time_off = timesheetData.time_off;
	}

	const rowData = mappedVisitTimesheets?.map((timesheet: any) => {
		const visit: any = timesheet.visit
		const job: any = timesheet?.job
		const staff: any = timesheet?.staff
		const start_time = moment(timesheet.job_start_time, 'HH:mm') || moment(timesheet.time_in, 'HH:mm');
		const end_time = moment(timesheet.time_off, 'HH:mm');
		const difference = end_time.diff(start_time, 'hours', true) || 0;
		
		const mappedRow = {
			record_id: timesheet?.id,
			staff_id: staff?.id,
			id: String(job?.id) + '_' + String(visit?.id) + '_' + String(staff?.id) + '_' + String(timesheet?.id),
			visit_id: visit?.id,
			name: staff?.staff_name,
			job_start_time: timesheet.job_start_time || timesheet?.time_in,
			pre_start_time: timesheet?.time_in,
			end_time: timesheet?.time_off,
			date: timesheet?.date,
			job: job?.job_num,
			description: job?.descriptionOfQuote,
			client_name: job?.clientData?.client_name,
			site: job?.site,
			difference: difference,
			confined_spaces: job?.job_type === 'Confined Spaces' ? true : false,
			timesheet_status: timesheet?.status,
		}
		console.log(mappedRow, 'row')
		return mappedRow
	})

	const columns = [
		{
			field: 'date',
			header: 'Date',
			body: (row: { date: Date }) => {
				return row.date
					? moment(row.date, 'DD/MM/YYYY').format('DD/MM/YYYY')
					: ''
			},
		},
		{
			field: 'visit_id',
			header: 'Visit #',
			body: (row: { visit_id: number }) => {
				return row.visit_id ? (
					<Badge type={'Green'} text={String(row.visit_id)} />
				) : (
					<Badge type={'Orange'} text={'No Visit'} />
				)
			},
		},
		{
			field: 'name',
			header: 'Name',
		},
		{
			field: 'job',
			header: 'Job',
		},
		{ field: 'client_name', header: 'Client' },
		{
			field: 'site',
			header: 'Site',
		},
		{ field: 'job_start_time', header: 'Job Start' },
		{ field: 'pre_start_time', header: 'Pre Start' },
		{ field: 'end_time', header: 'Time Off' },
		{ field: 'difference', header: 'Hours' },
		{
			field: 'confined_spaces',
			header: 'Confined Spaces?',
			body: (row: { confined_spaces: boolean }) => {
				return row.confined_spaces ? (
					<Badge type={'Warning'} text={'Confined Space'} />
				) : (
					<Badge type={'Green'} text={'No'} />
				)
			},
		},
		{ field: 'timesheet_status', header: 'Timesheet Status' },
		{
			field: 'operations',
			header: 'Operations',
			body: (row: any) => (
				<div className="flex-row space-y-2 mt-2 mb-2 items-start mr-4">
					<div className="flex items-center">
						<button
							onClick={async () => {
								const timeSheet = visitTimesheets?.find(
									(timeSheet: { id: any }) => timeSheet.id === row.record_id
								)
								console.log(row)
								await updateVisitTimesheet({ ...timeSheet, status: 'Approved' })
							}}
							className="flex items-center text-green-400">
							<CheckIcon className="h-4 w-4 mx-2" />
							<span>Mark Approved</span>
						</button>
					</div>
				</div>
			),
		},
	]

	const customHeaderTemplate = (data: { visit_id: number; date: string }) =>
		data.visit_id ? (
			<span className="text-gray-900 font-bold">
				Visit #{data?.visit_id} on {data?.date}
			</span>
		) : (
			<span className="text-gray-900 font-bold">No Visits</span>
		)

	const calculateHours = (startTime: string, endTime: string) => {
		const start_time = moment(startTime, 'HH:mm');
		const end_time = moment(endTime, 'HH:mm');
		const difference = end_time.diff(start_time, 'hours', true) || 0;
		
		return difference;
	};

	const totalVisitHours = () => {
		let total = 0;

		for (let data of rowData) {
				total += data.difference;
		}

		return total;
	};

	const footerGroup = (
		<ColumnGroup>
			<Row>
				<Column
					footer="Total Hours:"
					colSpan={9}
					footerStyle={{
						textAlign: 'right',
						background: '#f3f4f6',
						fontWeight: 'normal',
					}}
				/>
				<Column footer={totalVisitHours} footerStyle={{
						background: '#f3f4f6',
					}}/>
			</Row>
		</ColumnGroup>
	)

	return (
		<div className="w-full mx-auto mt-8 mb-28">
			{timesheetData && (
				<TwoColumnDetails
					heading="Payroll Timesheet Details"
					editBtn="Edit Payroll Timesheet"
					isEditable={false}>
					<Section title="Date" content={timesheetData.date} />
					<Section title="Status" content={timesheetData.status} />

					<Section title="Lunch Break" content={timesheetData.lunch_break} />
					<Section title="" content={''} />
					<Section title="" content={''} />
					<Section title="" content={''} />
					<Section title="Time On" content={timesheetData.time_on} />
					<Section title="Actual Start" content={timesheetData.actual_start} />
					<Section title="Time Off" content={timesheetData.time_off} />
					<Section
						title="Actual Finish"
						content={timesheetData.actual_finish}
					/>
					<Section title="" content={''} />
					<Section title="" content={''} />
					<Section title="Hours" content={calculateHours(timesheetData.time_on, timesheetData.time_off)} />
					<Section title="" content={''} />
					<Section title="Comments" content={timesheetData.comments} />
				</TwoColumnDetails>
			)}
			{
				<Table
					columns={columns}
					data={rowData}
					groupBy="visit_id"
					isLoading={false}
					title="Visits Timesheets"
					headerTemplate={customHeaderTemplate}
					footerTemplate={footerGroup}
					disableButtons
				/>
			}
		</div>
	)
}
