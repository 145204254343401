import { Spinner, Table } from 'common'
import { useState } from 'react'
import { VehicleServices } from 'services'
import { VehicleServiceForm } from './Form'
import { PencilIcon } from '@heroicons/react/24/solid'
import { numberFormat } from 'utilities'
import { Link, useLocation } from 'react-router-dom'
import { AppRoutes } from 'config'

interface ServiceTableProps {
	vehicle_id?: number
}

const ServiceTable = ({ vehicle_id }: ServiceTableProps) => {
	const location = useLocation()
	const [openForm, setOpenForm] = useState(false)
	const { data: vehicleServicesData, isLoading: vehicleServicesLoading } =
		VehicleServices.useVehicleServicesByVehicleId(vehicle_id)

	if (vehicleServicesLoading) {
		return <Spinner />
	}

	const columns = [
		{ field: 'date', header: 'Date' },
		{ field: 'invoice_number', header: 'Invoice Number' },
		{ field: 'extra_work_done', header: 'Extra Work Done' },
		{
			header: 'File',
			field: 'file',
			body: (row: { file: string }) => {
				return row.file != '' ? (
					<a className="text-blue-600" href={row.file} target="_blank">
						Link
					</a>
				) : (
					<a>No File Uploaded</a>
				)
			},
		},
		{
			field: 'cost',
			header: 'Cost',
			body: (row: { cost: number }) => {
				return numberFormat.format(row.cost || 0)
			},
		},
		{
			field: 'id',
			header: 'Edit',
			body: (row: { id: string }) => {
				return row.id != null ? (
					<Link
						to={{
							pathname: AppRoutes.privateRoutes.VehicleServicesEdit.replace(
								':id',
								row.id.toString()
							),
						}}
						state={{ background: location, name: 'editVehicleService' }}>
						<PencilIcon className="h-4 w-4 text-gray-500" />
					</Link>
				) : (
					<div></div>
				)
			},
		},
	]

	return (
		<>
			<Table
				columns={columns}
				data={vehicleServicesData}
				isLoading={vehicleServicesLoading}
				title="Vehicle Cost"
				ActionName="Add New Cost"
				setOpen={setOpenForm}
				disableButtons
			/>
			<VehicleServiceForm
				heading="Create Vehicle Cost"
				formType="create"
				open={openForm}
				setOpen={setOpenForm}
				vehicle_id={Number(vehicle_id)}
			/>
		</>
	)
}

export default ServiceTable
