import moment from 'moment'

export const columns = [
	{
		header: 'Created Date',
		field: 'created_at',
		body: (row: { created_at: Date }) => {
			return row.created_at ? moment(row.created_at).format('DD/MM/YYYY') : ''
		},
	},
	{
		header: 'File Type',
		field: 'file_type',
	},
	{
		header: 'File Description',
		field: 'file_description',
	},
	{
		header: 'Notes',
		field: 'notes',
	},
	{
		header: 'File',
		field: 'link',
		body: (rowData: { link: string }) => <a href={rowData.link}>Link</a>,
	},
]

export const vehicleFileColumns = [
	{
		header: 'Created Date',
		field: 'created_at',
		body: (row: { created_at: Date }) => {
			return row.created_at ? moment(row.created_at).format('DD/MM/YYYY') : ''
		},
	},
	{
		header: 'File Type',
		field: 'file_type',
	},
	{
		header: 'File Description',
		field: 'file_description',
	},
	// {
	// 	header: 'Notes',
	// 	field: 'notes',
	// },
	{
		header: 'File',
		field: 'link',
		body: (rowData: { link: string }) => <a href={rowData.link}>Link</a>,
	},
]
