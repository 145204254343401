import { IQuoteLine, IRates } from 'models/simple_quotes.model'

export const calculateLine = (
	rates: IRates[],
	line: IQuoteLine,
	field?: string
) => {
	// if (
	// 	field === 'length' ||
	// 	field === 'height' ||
	// 	field === 'width' ||
	// 	field === 'type' ||
	// 	field === 'calculation'
	// ) {
	// 	if (line.type !== 'Brick Guards') {
	// 		if (!line.height && !line.width) {
	// 			line.height = 1
	// 			line.width = 1
	// 		}
	// 		const calculation = line.calculation
	// 		console.log({calculation});
			
	// 		if (calculation === 'm2') {
	// 			line.meters = Math.floor(line.length * line.height * 100) / 100
	// 		} else {
	// 			line.meters =
	// 				Math.floor(line.length * line.height * line.width * 100) / 100
	// 		}
	// 	} else {
	// 		line.height = 0
	// 		line.width = 0
	// 		line.meters = Math.floor(line.length * 4 * 1.1 * 100) / 100
	// 	}
	// 	if (!line.labour_is_dirty) {
	// 		const rate = rates.find((rate) => rate.service === line.type)?.labour
	// 		line.labour = Math.floor(line.meters * Number(rate) * 100) / 100
	// 	}
	// 	if (!line.transport_is_dirty) {
	// 		const rate = rates.find((rate) => rate.service === line.type)?.transport
	// 		line.transport =
	// 			Math.floor((line.meters / 250) * 2.5 * Number(rate) * 100) / 100
	// 	}
	// 	if (!line.scrim_is_dirty) {
	// 		const rate = rates.find((rate) => rate.service === line.type)?.scrim
	// 		line.scrim = Math.ceil(line.meters * Number(rate) * 100) / 100
	// 	}
	// 	if (!line.hire_is_dirty) {
	// 		const rate = rates.find((rate) => rate.service === line.type)?.hire
	// 		if (line.type === 'Stair Access') {
	// 			line.hire =
	// 				Math.floor((line.meters * Number(rate) + 10 * 4) * 100) / 100
	// 		} else {
	// 			line.hire = Math.floor(line.meters * Number(rate) * 100) / 100
	// 		}
	// 	}
	// }
	line.total =
		Math.floor((line.labour + line.transport + line.scrim + line.shrinkwrap + (line.hire * line.hire_duration)) * 100) /
		100

	return line
}
