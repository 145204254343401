import { co } from '@fullcalendar/core/internal-common'
import { Badge, PageHeading, Spinner, Table } from 'common'
import { VisitTimesheetForm } from 'components/VisitTimesheets'
import moment from 'moment'
import { useEffect, useState } from 'react'
import {
	JobsServices,
	StaffServices,
	TimesheetServices,
	VisitServices,
	VisitTimesheetsServices,
} from 'services'

interface VisitsTimesheetsTableProps {
	job_id?: number
}
export const VisitsTimesheetsTable = ({
	job_id,
}: VisitsTimesheetsTableProps) => {
	const { data: timesheetData, isLoading: timesheetLoading } =
		VisitTimesheetsServices.useVisitTimesheets()
	const { data, isLoading } = TimesheetServices.useTimesheetsByStatus('Pending')
	const [openVisitTimesheetForm, setOpenVisitTimesheetForm] = useState(false)
	const [timesheetId, setTimesheetId] = useState(undefined)

	useEffect(() => {
		if (!openVisitTimesheetForm) {
			setTimesheetId(undefined)
		}
	}, [openVisitTimesheetForm])

	if (timesheetLoading || isLoading) {
		return <Spinner />
	}

	const findStartTime = (staffId: number, date: string) => {
		const entry = data.find(
			(item: { staff_id: number; date: string }) =>
				item.staff_id === staffId && item.date === date
		)
		return entry ? entry.time_on : null
	}

	const sortAndGroupEntries = (data: any) => {
		const groupedEntries: any = {}

		data.forEach((item: { staff: { id: any }; date: any }) => {
			const key = `${item.staff.id}_${item.date}`
			if (!groupedEntries[key]) {
				groupedEntries[key] = []
			}
			groupedEntries[key].push(item)
		})

		Object.keys(groupedEntries).forEach((key) => {
			groupedEntries[key].sort(
				(
					a: { time_in: moment.MomentInput },
					b: { time_in: moment.MomentInput }
				) => {
					const aTimeIn = moment(a.time_in, 'HH:mm').valueOf()
					const bTimeIn = moment(b.time_in, 'HH:mm').valueOf()
					return aTimeIn - bTimeIn
				}
			)
		})

		return groupedEntries
	}

	const updateStartTimeForMultipleEntries = (groupedEntries: any) => {
		Object.keys(groupedEntries).forEach((key) => {
			const entries = groupedEntries[key]
			for (let i = 0; i < entries.length; i++) {
				if (!entries[i].job_start_time) {
					if (i === 0) {
						const lookedUpStartTime = findStartTime(
							entries[i].staff.id,
							entries[i].date
						)
						entries[i].job_start_time =
							lookedUpStartTime || entries[i].time_in
					} else {
						entries[i].job_start_time = entries[i - 1].time_off
					}
				}
			}
		})

		return Object.values(groupedEntries).flat()
	}

	const groupedEntries = sortAndGroupEntries(timesheetData)

	const updatedTimesheetData: any =
		updateStartTimeForMultipleEntries(groupedEntries)
		
	const rowData = updatedTimesheetData
		?.filter((timesheet: { job_id: number | undefined }) => Number(timesheet.job_id) === job_id)
		?.sort((a: any, b: any) => a.id - b.id)
		?.map((timesheet: any) => {
			const visit: any = timesheet.visit
			const job: any = timesheet?.job
			const staff: any = timesheet?.staff

			const start_time =
				moment(timesheet.job_start_time, 'HH:mm') ||
				moment(timesheet.time_in, 'HH:mm')
			const end_time = moment(timesheet.time_off, 'HH:mm')
			const difference = end_time.diff(start_time, 'hours', true) || 0
			return {
				record_id: timesheet?.id,
				staff_id: staff?.id,
				id: String(job?.id) + '_' + String(visit?.id) + '_' + String(staff?.id),
				visit_id: visit?.id,
				name: staff?.staff_name,
				job_start_time: timesheet.job_start_time || timesheet?.time_in,
				pre_start_time: timesheet?.time_in,
				end_time: timesheet?.time_off,
				date: timesheet?.date,
				job: job?.job_num,
				description: job?.descriptionOfQuote,
				client_name: job?.clientData?.client_name,
				site: job?.site,
				hours: difference,
				confined_spaces: job?.job_type === 'Confined Spaces' ? true : false,
				timesheet_status: timesheet?.status,
			}
		})

	const columns = [
		{
			field: 'date',
			header: 'Date',
			body: (row: { date: Date }) => {
				return row.date
					? moment(row.date, 'DD/MM/YYYY').format('DD/MM/YYYY')
					: ''
			},
		},
		{
			field: 'visit_id',
			header: 'Visit #',
			body: (row: { visit_id: number }) => {
				return row.visit_id
					? <Badge type={'Green'} text={String(row.visit_id)} />
					: <Badge type={'Orange'} text={'Manual Entry'} />
			},
		},
		{
			field: 'name',
			header: 'Name',
		},
		{ field: 'job_start_time', header: 'Job Start' },
		{ field: 'pre_start_time', header: 'Pre Start' },
		{ field: 'end_time', header: 'Time Off' },
		{ field: 'hours', header: 'Hours' },
		{
			field: 'confined_spaces',
			header: 'Confined Spaces?',
			body: (row: { confined_spaces: boolean }) => {
				return row.confined_spaces ? (
					<Badge type={'Warning'} text={'Confined Space'} />
				) : (
					<Badge type={'Green'} text={'No'} />
				)
			},
		},
		{ field: 'timesheet_status', header: 'Timesheet Status' },
	]

	return (
		<>
			<PageHeading
				title="Visit Timesheets"
				createBtn={'Create Visit Timesheet'}
				isEditable={false}
				setOpen={setOpenVisitTimesheetForm}
			/>
			<Table
				columns={columns}
				data={rowData}
				groupBy="visit_id"
				isLoading={false}
				title="Visits Timesheets"
				disableButtons
			/>
			<VisitTimesheetForm
				timesheet_id={timesheetId}
				heading="Create Visit Timesheet"
				setOpen={setOpenVisitTimesheetForm}
				open={openVisitTimesheetForm}
			/>
		</>
	)
}
