import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQuery } from '@tanstack/react-query'
import moment from 'moment'

export const useVehicleRUCsByVehicleId = (ruc_id?: string | number) => {
	const { showError } = useContext(NotificationsContext)
	const { getRequest } = useApi()

	const fetchAllVehicleRUCsByVehicleId = async () => {
		try {
			if (!ruc_id) return null
			const response = await getRequest(
				AppRoutes.serverVehicleRoutes.getAllVehicleRUCsByVehicleId,
				ruc_id
			)

			const sortedData = response.data.sort((a: any, b: any) => {
				const dateA = moment(a.date_brought, 'DD/MM/YYYY')
				const dateB = moment(b.date_brought, 'DD/MM/YYYY')

				return dateB.diff(dateA)
			})

			return sortedData
		} catch (error) {
			showError('Something went wrong getting Vehicle RUCs')
			throw new Error('Something went wrong getting Vehicle RUCs')
		}
	}

	const { data, isLoading, error } = useQuery(
		['vehicleRUCs', ruc_id],
		fetchAllVehicleRUCsByVehicleId
	)

	return { data, isLoading, error }
}
