import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { VehiclesComponents } from 'components'

export const VehicleRUCEdit = () => {
	const [openForm, setOpenForm] = useState(true)
	const navigate = useNavigate()

	const { id } = useParams()

	useEffect(() => {
		if (!openForm) {
			navigate(-1)
		}
	}, [openForm])

	return (
		<VehiclesComponents.VehicleRUCForm
			formType="update"
			heading="Edit Vehicle RUC"
			open={openForm}
			setOpen={setOpenForm}
			ruc_id={Number(id)}
		/>
	)
}
