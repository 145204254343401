import React from 'react'
import { Input } from 'common'

interface Props {
	values: {
		emergency_contact_name: string
		emergency_contact_mobile: string
		emergency_contact_email: string
	}
	handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
	handleBlur: (e: React.FocusEvent<HTMLInputElement>) => void
}

export const EmergencyContact: React.FC<Props> = ({
	values,
	handleChange,
	handleBlur,
}) => {
	return (
		//
		<div>
			<h3 className="px-4 pt-2 text-lg font-semibold leading-5">
				Emergency Contact
			</h3>
			<div>
				<div className="flex items-center px-2">
					<Input
						title="Name"
						id="emergency_contact_name"
						type="text"
						handleChange={handleChange}
						handleBlur={handleBlur}
						value={values.emergency_contact_name}
						placeholder=""
					/>
					<Input
						title="Contact #"
						id="emergency_contact_mobile"
						type="text"
						handleChange={handleChange}
						handleBlur={handleBlur}
						value={values.emergency_contact_mobile}
						placeholder=""
					/>
				</div>
				<div className="w-1/2 pl-2">
					<Input
						title="Email"
						id="emergency_contact_email"
						type="text"
						handleChange={handleChange}
						handleBlur={handleBlur}
						value={values.emergency_contact_email}
						placeholder=""
					/>
				</div>
			</div>
		</div>
	)
}
