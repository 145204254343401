import { useState } from 'react'

interface RadioButtonProps {
	title?: string
	options: Array<{ value: string; label: string }>
	id: string
	onChange: (id: string, value: string) => void
	value?: string
	orientation?: 'horizontal' | 'vertical'
}

export function RadioButton({
	title,
	options,
	id,
	onChange,
	value = '',
	orientation = 'vertical',
}: RadioButtonProps) {
	const [selected, setSelected] = useState<string>(value)

	const handleSelectedChange = (item: string) => {
		setSelected(item)
		onChange(id, item)
	}

	const isVertical = orientation === 'vertical'

	return (
		<div>
			<div>
				<label className="w-full block text-sm font-medium text-gray-700">
					{title}
				</label>
			</div>
			<fieldset className={isVertical ? 'space-y-3' : 'space-x-3 flex'}>
				<legend className="sr-only">{title}</legend>
				{options.map((option) => (
					<div
						className={
							isVertical
								? 'relative flex items-start'
								: 'relative flex items-center'
						}
						key={option.value}>
						<div className="flex items-center h-5">
							<input
								id={option.value}
								name={id}
								value={option.value}
								onChange={(e) => {
									const val = e.target.value
									handleSelectedChange(val)
								}}
								checked={selected === option.value}
								type="radio"
								className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
							/>
						</div>
						<div className="ml-3 text-sm">
							<label
								htmlFor={option.value}
								className="font-medium text-gray-700">
								{option.label}
							</label>
						</div>
					</div>
				))}
			</fieldset>
		</div>
	)
}
