import { useState } from 'react'
import { FilterMatchMode } from 'primereact/api'
import { DataTable } from 'primereact/datatable'
import { DataTableServices, JobsServices } from 'services'
import { Container, DataTableHeader, PageHeading, Badge } from 'common'
import { tableFilterMatchModeOptions } from 'services/DataTable'
import { Column } from 'primereact/column'
import { Link, useLocation } from 'react-router-dom'
import { PencilIcon, FolderIcon, PlusIcon } from '@heroicons/react/24/solid'
import { AppRoutes } from 'config'
import { JobsComponents } from 'components'
import moment from 'moment'
import { PageJobHeading } from 'common/PageJobHeader'

function getDescendantProp(obj: any, desc: string) {
	var arr = desc.split('.')
	while (arr.length && (obj = obj[arr.shift() || 0]));
	return obj
}

function sortNZDate(
	dataToSort: any[],
	field: string,
	order: number | null | undefined
) {
	try {
		const sortData = dataToSort.map((data: any) => {
			// Handle fields that contain things like subData.field
			//
			const date = getDescendantProp(data, field)

			try {
				const timestamp = new Date(
					date.split('/').reverse().join('/')
				).getTime()
				return { data, timestamp }
			} catch {
				return { data, timestamp: 0 }
			}
		})
		const sortOrder = order
		// Sort based no sort order
		sortData.sort((a: any, b: any) => {
			if (sortOrder === 1) {
				return a.timestamp - b.timestamp
			} else {
				return b.timestamp - a.timestamp
			}
		})

		// Return the original data with the new sort order
		return sortData.map((data: any) => data.data)
	} catch {
		return dataToSort
	}
}

export const JobsMainTable = () => {
	const location = useLocation()
	const { data, isLoading, enableCreateUpdate } = JobsServices.useJobs()
	const { data: handoverData, isLoading: handoverLoading } =
		JobsServices.useHandovers()
	// const [openJobForm, setOpenJobForm] = useState(false)
	const [openDayWorksJobForm, setOpenDayWorksJobForm] = useState(false)

	const jobDataWithHandoverData = data?.map((job: any) => {
		if (handoverData === undefined) return job
		const handover = handoverData?.find(
			(handover: any) => Number(handover.job_id) === Number(job.id)
		)
		return {
			...job,
			handoverData: handover,
		}
	})

	const {
		clearFilter,
		filters,
		globalFilterValue,
		setFilters,
		setGlobalFilterValue,
		globalFilterFields,
		FilterColumn,
		dataTableReference,
	} = DataTableServices.useFiltersDataTable({
		initialFilters: [
			{
				filterName: 'job_status',
				filterInitialValue: 'Completed',
				filterInitialMatchMode: FilterMatchMode.NOT_EQUALS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equalsOrNot,
				filterOptions: [
					'Pending Handover',
					'In Progress',
					'Completed',
					'Invoiced',
				],
			},
			{
				filterName: 'status',
				filterInitialValue: 'Active',
				filterInitialMatchMode: FilterMatchMode.EQUALS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equalsOrNot,
				filterOptions: ['Active', 'Inactive'],
			},
			{
				filterName: 'branch',
				filterInitialValue: null,
				filterInitialMatchMode: FilterMatchMode.EQUALS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equalsOrNot,
				filterOptions: ['Auckland', 'Wellington'],
			},
		],
		additionalGlobalFilterFields: [
			'job_num',
			'site',
			'clientData.client_name',
			'descriptionOfQuote',
			'name',
		],
	})

	const header = DataTableHeader({
		clearFilter,
		globalFilterValue,
		filters,
		setFilters,
		setGlobalFilterValue,
		dataTableReference,
		customFilters: [
			{
				field: 'branch',
				label: 'Auckland',
				value: 'Auckland',
				color: 'success',
			},
			{
				field: 'branch',
				label: 'Wellington',
				value: 'Wellington',
				color: 'warning',
			},
		],
	})

	return (
		<div className="card">
			<PageJobHeading
				title="Jobs"
				createBtn="Schedule Visit"
				isEditable={false}
				secondCreateBtn="Create Job"
				secondSetOpen={setOpenDayWorksJobForm}
			/>

			<Container>
				<DataTable
					ref={dataTableReference}
					value={jobDataWithHandoverData}
					paginator
					showGridlines
					rows={100}
					paginatorPosition="top"
					rowsPerPageOptions={[25, 50, 100]}
					loading={isLoading || handoverLoading}
					dataKey="id"
					sortField="id"
					sortOrder={-1}
					filters={filters}
					globalFilterFields={globalFilterFields}
					header={header}
					stripedRows
					emptyMessage="No jobs found.">
					<Column
						field="job_num"
						header="Job #"
						style={{ width: '7rem' }}
						sortable
						body={(rowData) => (
							<Link
								to={AppRoutes.privateRoutes.JobDetails.replace(
									':id',
									rowData.id
								)}
								className="flex items-center">
								<FolderIcon className="h-4 w-4 mx-2" />
								<># {rowData.job_num}</>
							</Link>
						)}
					/>
					<Column field="name" header="Name" />
					<Column field="clientData.client_name" header="Client" />
					<Column field="branch" header="Branch" {...FilterColumn.branch} />
					<Column field="site" header="Site Address" />
					<Column
						field="descriptionOfQuote"
						header="Description"
						style={{ maxWidth: '200px', textAlign: 'left' }}
					/>
					<Column
						field="job_type"
						header="Job Type"
						style={{ maxWidth: '150px', textAlign: 'left' }}
					/>
					<Column
						field="start_date"
						header="Start Date"
						sortable
						sortFunction={(e) => {
							return sortNZDate(e.data, e.field, e.order)
						}}
						body={(rowData) => (
							<>{moment(new Date(rowData.start_date)).format('DD/MM/YYYY')}</>
						)}
					/>
					{/* <Column
						field="end_date"
						header="Finish Date"
						sortable
						sortFunction={(e) => {
							return sortNZDate(e.data, e.field, e.order)
						}}
						body={(rowData) => (
							<>
								{rowData.job_type !== 'Day Works'
									? rowData.end_date
										? moment(new Date(rowData.end_date)).format('DD/MM/YYYY')
										: 'N/A'
									: ''}
							</>
						)}
					/> */}
					<Column
						field="job_status"
						header="Job Status"
						{...FilterColumn.job_status}
					/>
					<Column field="on_hire" header="On Hire" {...FilterColumn.on_hire} />
					<Column field="notes" header="Notes" />
					{/* <Column field="branding" header="Branding" hidden /> */}
					<Column
						field="status"
						header="Status"
						bodyStyle={{ textAlign: 'center' }}
						body={(rowData) => (
							<Badge text={rowData.status} type={rowData.status} />
						)}
						{...FilterColumn.status}
					/>
					<Column
						field="confined_spaces"
						header="Confined Spaces?"
						bodyStyle={{ textAlign: 'center' }}
						body={(rowData) =>
							rowData.job_type == 'Confined Spaces' ? (
								<Badge text={'Confined Space'} type={'Warning'} />
							) : (
								<Badge text={'No'} type={'Green'} />
							)
						}
					/>
					<Column
						field="handoverData.work_safe"
						header="Worksafe Notified?"
						bodyStyle={{ textAlign: 'center' }}
						body={(rowData) =>
							rowData?.handoverData?.work_safe == 'Yes' ? (
								<Badge text={'Yes'} type={'Green'} />
							) : rowData?.handoverData?.work_safe == 'Not Required' ? (
								<Badge text={'Not Required'} type={'Green'} />
							) : rowData?.handoverData?.work_safe == 'No' ? (
								<Badge text={'No'} type={'Alert'} />
							) : (
								<Badge text={'?'} type={'Warning'} />
							)
						}
					/>
					<Column
						field="handoverData.worksafe_uploaded_when"
						header="Worksafe Notification Expiry Date"
						sortable
						sortFunction={(e) => {
							return sortNZDate(e.data, e.field, e.order)
						}}
						body={(rowData) => (
							<>{rowData?.handoverData?.worksafe_uploaded_when}</>
						)}
					/>
					{enableCreateUpdate && (
						<Column
							header="Edit"
							body={(rowData) => (
								<Link
									to={{
										pathname: AppRoutes.privateRoutes.JobEdit.replace(
											':id',
											rowData.id
										),
									}}
									state={{ background: location, name: 'editJob' }}>
									<PencilIcon className="text-gray-600 h-4 w-4" />
								</Link>
							)}
						/>
					)}
				</DataTable>
			</Container>
			{/* <JobsComponents.JobForm
				formType="create"
				heading="Create Job"
				open={openJobForm}
				setOpen={setOpenJobForm}
			/> */}
			<JobsComponents.DayWorksForm
				formType="create"
				heading="Create Job"
				open={openDayWorksJobForm}
				setOpen={setOpenDayWorksJobForm}
			/>
		</div>
	)
}
