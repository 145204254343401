// Create Address form

import { CreateFile, DateSelect, Input, TextArea } from 'common'
import { useFormik } from 'formik'
import { ClientServices, AddressServices, VehicleServices } from 'services'
import { SideModal, Spinner, Dropdown } from 'common'
import { AppConfig } from 'config'
import Autocomplete from 'react-google-autocomplete'
import { SearchAddressV2 } from 'common/Form/Address'
import { FocusEvent } from 'react'
import * as Yup from 'yup'

interface IProps {
	service_id?: number
	vehicle_id?: number
	heading: string
	setOpen: (open: boolean) => void
	formType: 'create' | 'update'
	open: boolean
}

interface IInitialValues {
	vehicle_id: string
	date: string
	invoice_number: string
	extra_work_done: string
	file: string
	cost: number
}

export const VehicleServiceForm = ({
	service_id,
	vehicle_id,
	heading,
	setOpen,
	formType,
	open,
}: IProps) => {
	const { createVehicleService } = VehicleServices.useCreateVehicleService()
	const { updateVehicleService } = VehicleServices.useUpdateVehicleService()
	const { data, isLoading } = VehicleServices.useVehicleServiceById(service_id)

	const initialValues: IInitialValues = {
		vehicle_id: data?.vehicle_id || vehicle_id,
		date: data?.date || '',
		invoice_number: data?.invoice_number || '',
		extra_work_done: data?.extra_work_done || '',
		file: data?.file || '',
		cost: data?.cost || 0,
	}

	const validationSchema = Yup.object({
		date: Yup.string().required('The Date is required'),
		// extra_work_done: Yup.number().required('This is required'),
		// cost: Yup.string().required('The Cost is required'),
	})

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true)

			if (formType === 'create') {
				const vehicleServiceData = {
					vehicle_id: values.vehicle_id,
					date: values.date,
					invoice_number: values.invoice_number,
					extra_work_done: values.extra_work_done,
					file: values.file,
					cost: values.cost,
				}
				await createVehicleService(vehicleServiceData)
			} else {
				const vehicleServiceData = {
					vehicle_id: values.vehicle_id,
					date: values.date,
					invoice_number: values.invoice_number,
					extra_work_done: values.extra_work_done,
					file: values.file,
					cost: values.cost,
				}
				await updateVehicleService(Number(service_id), vehicleServiceData)
			}

			setSubmitting(false)
			formik.resetForm()
			setOpen(false)
		},
	})

	if (vehicle_id && isLoading) {
		return <Spinner />
	}

	return (
		<>
			<SideModal
				heading={heading}
				open={open}
				setOpen={setOpen}
				handleSubmit={formik.handleSubmit}
				isLoading={formik.isSubmitting}
				formType={formType}>
				<div>
					<div className="flex items-center px-2">
						<div className="w-1/2">
							<DateSelect
								id="date"
								title="Date"
								value={formik.values.date}
								onChange={formik.setFieldValue}
								error={formik.errors.date}
							/>
						</div>
					</div>
					<div className="flex items-center px-2">
						<div className="w-1/2">
							<Input
								title="Invoice Number"
								placeholder="Invoice Number"
								id="invoice_number"
								type="text"
								error={formik.errors.invoice_number}
								value={formik.values.invoice_number}
								handleChange={formik.handleChange}
								handleBlur={formik.handleBlur}
							/>
						</div>
						<div className="w-1/2">
							<Input
								title="Cost"
								placeholder="Cost"
								id="cost"
								type="text"
								error={formik.errors.cost}
								value={formik.values.cost}
								handleChange={formik.handleChange}
								handleBlur={formik.handleBlur}
							/>
						</div>
					</div>
					<div className="flex items-center px-2">
						<TextArea
							title="Extra Work Done"
							id="extra_work_done"
							type="text"
							error={formik.errors.extra_work_done}
							value={formik.values.extra_work_done}
							handleChange={formik.handleChange}
							handleBlur={formik.handleBlur}
						/>
					</div>
					<div className="w-1/2 pl-2">
						{/* <h4 className="text-md font-normal leading-5 px-4">File</h4> */}
						<CreateFile
							field="file"
							setFieldValue={formik.setFieldValue}
							value={formik.values.file}
						/>
					</div>
				</div>
			</SideModal>
		</>
	)
}
