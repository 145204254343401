import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQueryClient } from '@tanstack/react-query'
import { IStaffRow } from 'models/staff.model'

export const useUpdateVehicleService = () => {
	const { showSuccess, showError } = useContext(NotificationsContext)
	const { postRequest } = useApi()
	const queryClient = useQueryClient()

	const updateVehicleService = async (
		vehicle_id: string | number,
		vehicle_service: unknown
	) => {
		try {
			const response: IStaffRow | undefined = await postRequest(
				AppRoutes.serverVehicleRoutes.updateVehicleService,
				vehicle_service,
				vehicle_id
			)
			queryClient.refetchQueries(['vehicles'])
			queryClient.refetchQueries(['vehicleServices', vehicle_id])
			showSuccess('Vehicle Service updated successfully')
			return response
		} catch (error) {
			showError('Something went wrong updating vehicle service')
			throw new Error((error as Error).message)
		}
	}
	return { updateVehicleService }
}
