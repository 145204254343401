import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { VehiclesComponents } from 'components'

export const VehicleServiceEdit = () => {
	const [openForm, setOpenForm] = useState(true)
	const navigate = useNavigate()

	const { id } = useParams()

	useEffect(() => {
		if (!openForm) {
			navigate(-1)
		}
	}, [openForm])

	return (
		<VehiclesComponents.VehicleServiceForm
			formType="update"
			heading="Edit Vehicle Cost"
			open={openForm}
			setOpen={setOpenForm}
			service_id={Number(id)}
		/>
	)
}
