import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQueryClient } from '@tanstack/react-query'
import { IStaffRow } from 'models/staff.model'

export const useCreateVehicleRUC = () => {
	const { showSuccess, showError } = useContext(NotificationsContext)
	const { putRequest } = useApi()
	const queryClient = useQueryClient()

	const createVehicleRUC = async (
		vehicle_ruc: unknown
	): Promise<IStaffRow | undefined> => {
		try {
			const response: IStaffRow | undefined = await putRequest(
				AppRoutes.serverVehicleRoutes.createVehicleRUC,
				vehicle_ruc
			)
			queryClient.refetchQueries(['vehicles'])
			queryClient.refetchQueries(['vehicleRUCs'])
			showSuccess('Vehicle Service created successfully')
			return response
		} catch (error) {
			showError('Something went wrong creating Vehicle RUC')
			throw new Error((error as Error).message)
		}
	}
	return { createVehicleRUC }
}
