import { IQuoteAdditionalLines, IQuoteLine } from 'models'

export const totalsFn = {
	calculateLabourTotal: (data: IQuoteLine[]): number => {
		return data.reduce((acc, curr) => acc + Number(curr.labour), 0)
	},
	calculateTransportTotal: (data: IQuoteLine[]): number => {
		return data.reduce((acc, curr) => acc + Number(curr.transport), 0)
	},
	calculateScrimTotal: (data: IQuoteLine[]): number => {
		return data.reduce((acc, curr) => acc + Number(curr.scrim), 0)
	},
	calculateAdditionalTotal: (data: IQuoteAdditionalLines[]): number => {
		return data.reduce((acc, curr) => acc + Number(curr.total_cost), 0)
	},
	calculateHireTotal: (lines: IQuoteLine[]): number => {
		const linesTotal = lines.reduce(
			(acc, curr) => acc + (Number(curr.hire) * Number(curr.hire_duration)),
			0
		)
		return linesTotal
	},
	calculateTotals: (
		edTotal: number,
		addOnsTotal: number,
		weekTotal: number
	): number => {
		return edTotal + addOnsTotal + weekTotal
	},
}
