import { Document, Image, Link, Page, Text, View } from '@react-pdf/renderer'
import { QuoteData, IQuoteLine, IQuoteAdditionalLines } from 'models'
import { styles } from './Styles'
import { Heading, ClientInfo } from './Heading'
import { Description } from './Description'
import { ColumnTypes, Table } from './Table'
import * as cover from 'assets/cover.png'

interface QuotePDFDocumentProps {
	quote_data: QuoteData
	quote_lines: IQuoteLine[]
	quote_addons: IQuoteAdditionalLines[]
}

export const QuotePDFDocument = ({
	quote_data,
	quote_lines,
	quote_addons,
}: QuotePDFDocumentProps) => {
	const linesLabourTotal = quote_lines
		.map((line) => Number(line.labour))
		.reduce((acc, curr) => acc + curr, 0)
	const linesTransportTotal = quote_lines
		.map((line) => Number(line.transport))
		.reduce((acc, curr) => acc + curr, 0)
	const linesScrimTotal = quote_lines
		.map((line) => Number(line.scrim))
		.reduce((acc, curr) => acc + curr, 0)
	const linesShrinkwrapTotal = quote_lines
		.map((line) => Number(line.shrinkwrap))
		.reduce((acc, curr) => acc + curr, 0)
	const linesHireTotal = quote_lines
		.map((line) => Number(line.hire))
		.reduce((acc, curr) => acc + curr, 0)
	const additionalTotal = quote_addons
		.map((addon) => Number(addon.total_cost))
		.reduce((acc, curr) => acc + curr, 0)
	const total =
		linesLabourTotal +
		linesTransportTotal +
		linesScrimTotal +
		linesShrinkwrapTotal +
		additionalTotal +
		linesHireTotal
	const client = quote_data?.clientData?.client_name	
	const job = quote_data?.jobName

	return (
		<Document>
			<Page size="A4">
				<View style={{ position: 'relative', width: '100%', height: '100%' }}>
					<Image style={styles.cover} src={cover.default} />
					<Text style={styles.job} hyphenationCallback={(word) => [word]}>
						{job || ' '}
					</Text>
					<Text style={styles.client} hyphenationCallback={(word) => [word]}>
						{client || ' '}
					</Text>
				</View>
			</Page>
			<Page size="A4" style={styles.page}>
				<Heading />
				<Text style={styles.quoteText}>Quotation</Text>
				<ClientInfo quote={quote_data} />
				<Description description={quote_data.scope_of_work} />
				<View>
					<Text style={styles.heading}>Quote Lines</Text>
					<Table type={ColumnTypes.quote_lines} data={quote_lines} />
				</View>
				<View>
					<Text style={styles.heading}>Additional Items</Text>
					<Table type={ColumnTypes.quote_addons} data={quote_addons} />
				</View>
				<View style={{ marginTop: 20 }}>
					<Table type={ColumnTypes.totals} data={total} />
				</View>

				{/* <Text style={styles.subTextBoldRed}>All prices exclude GST</Text> */}

				<View>
					{quote_data.terms && (
						<>
							<Text style={styles.heading}>Additional Conditions</Text>
							<Text style={{ ...styles.subTextMargin }}>
								{quote_data.terms}
							</Text>
						</>
					)}
					<Text style={{ ...styles.subTextMargin }}>
						<Link src="https://tana.scaffm8.co.nz/Terms_and_Conditions.pdf">
							View our Terms and Conditions here
						</Link>
					</Text>
				</View>
			</Page>
			{quote_data?.files?.length > 0 ? (
				<Page size="A4" style={styles.page}>
					<View style={styles.imageRow}>
						{quote_data?.files?.map(
							(
								image: {
									url: string
									description: string
									section_label: string
								},
								index: number
							) => (
								<View style={styles.image}>
									<Image
										key={index}
										src={image.url}
										style={{ maxWidth: '100%', maxHeight: '100%' }}
									/>
									<View
										style={{
											flexDirection: 'row',
											gap: 5,
											alignItems: 'center',
											marginTop: 5,
										}}>
										<Text
											style={{
												...styles.subTextBold,
												fontSize: 10,
												margin: 0,
											}}>
											Description:
										</Text>
									</View>
									<Text style={styles.imageText}>{image.description}</Text>
									<View
										style={{
											flexDirection: 'row',
											gap: 5,
											alignItems: 'center',
											marginTop: 50,
										}}>
										<Text
											style={{
												...styles.subTextBold,
												fontSize: 10,
												margin: 0,
											}}>
											Section:
										</Text>
									</View>
									<Text style={styles.imageText}>{image.section_label}</Text>
								</View>
							)
						)}
					</View>
				</Page>
			) : null}
		</Document>
	)
}
