import { Spinner, Table } from 'common'
import { useState } from 'react'
import { VehicleServices } from 'services'
import { VehicleRUCForm } from './Form'
import { PencilIcon } from '@heroicons/react/24/solid'
import { numberFormat } from 'utilities'
import { Link, useLocation } from 'react-router-dom'
import { AppRoutes } from 'config'

interface RUCTableProps {
	vehicle_id?: number
}

const RUCTable = ({ vehicle_id }: RUCTableProps) => {
	const location = useLocation()
	const [openForm, setOpenForm] = useState(false)
	const { data: vehicleRUCsData, isLoading: vehicleRUCsLoading } =
		VehicleServices.useVehicleRUCsByVehicleId(vehicle_id)

	if (vehicleRUCsLoading) {
		return <Spinner />
	}

	const columns = [
		{ field: 'start', header: 'Start' },
		{ field: 'end', header: 'End' },
		{ field: 'date_brought', header: 'Date Brought' },
		{ field: 'units', header: 'Units' },
		{
			field: 'cost',
			header: 'Cost',
			body: (row: { cost: number }) => {
				return numberFormat.format(row.cost || 0)
			},
		},
		{
			field: 'id',
			header: 'Edit',
			body: (row: { id: string }) => {
				return row.id != null ? (
					<Link
						to={{
							pathname: AppRoutes.privateRoutes.VehicleRUCsEdit.replace(
								':id',
								row.id.toString()
							),
						}}
						state={{ background: location, name: 'editVehicleRUC' }}>
						<PencilIcon className="h-4 w-4 text-gray-500" />
					</Link>
				) : (
					<div></div>
				)
			},
		},
	]

	return (
		<>
			<Table
				columns={columns}
				data={vehicleRUCsData}
				isLoading={vehicleRUCsLoading}
				title="Vehicle RUCs"
				ActionName="Add New RUC"
				setOpen={setOpenForm}
				disableButtons
			/>
			<VehicleRUCForm
				heading="Create Vehicle RUC"
				formType="create"
				open={openForm}
				setOpen={setOpenForm}
				vehicle_id={Number(vehicle_id)}
				start={Number(vehicleRUCsData[0]?.end) || 0}
			/>
		</>
	)
}

export default RUCTable
