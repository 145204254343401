import { IQuoteLine, IQuoteZones, IRates } from 'models/simple_quotes.model'
import { calculateLine } from './quoteLinesOperations'

const initLine: IQuoteLine = {
	id: null,
	calculation: 'm2',
	zone_id: null,
	zone_label: '',
	type: '',
	description: '',
	meters: 1,
	hire: 0,
	hire_duration: 1,
	shrinkwrap: 0,
	total: 0,
	length: 1,
	height: 1,
	width: 1,
	labour_is_dirty: false,
	transport_is_dirty: false,
	scrim_is_dirty: false,
	shrinkwrap_is_dirty: false,
	hire_is_dirty: false,
	labour: 0,
	transport: 0,
	scrim: 0,
}

const addNewQuoteLine = (quote_lines: IQuoteLine[]): IQuoteLine[] => {
	quote_lines.push(initLine)
	return quote_lines
}

const removeQuoteLine = (
	index: number,
	quote_lines: IQuoteLine[]
): IQuoteLine[] => {
	const newQuoteLines = [...quote_lines]
	newQuoteLines.splice(index, 1)
	return newQuoteLines
}

const updateQuoteLine = (
	index: number,
	quote_lines: IQuoteLine[],
	field: string,
	value: string | number | boolean
): IQuoteLine[] => {
	const newQuoteLines: IQuoteLine[] = quote_lines.map((line, i) => {
		if (i === index) {
			const newLine = {
				...line,
				[field]: value,
				labour_is_dirty: field === 'labour' ? true : line.labour_is_dirty,
				transport_is_dirty:
					field === 'transport' ? true : line.transport_is_dirty,
				scrim_is_dirty: field === 'scrim' ? true : line.scrim_is_dirty,
				shrinkwrap_is_dirty: field === 'shrinkwrap' ? true : line.scrim_is_dirty,
				hire_is_dirty: field === 'hire' ? true : line.hire_is_dirty,
			}
			if (field === 'type') {
				newLine.description = newLine.type
			}
			return newLine
		}
		return line
	})

	return newQuoteLines
}

const calculateQuoteLines = (
	rates: IRates[],
	quote_lines: IQuoteLine[],
	field?: string
): IQuoteLine[] => {
	const newQuoteLines = quote_lines.map((line) => {
		const calculatedLine = calculateLine(rates, line, field)
		return calculatedLine
	})
	return newQuoteLines
}

const checkZones = (
	zones: IQuoteZones[],
	quote_lines: IQuoteLine[]
): IQuoteLine[] => {
	const newQuoteLines = quote_lines.map((line) => {
		const zone = zones.find((zone) => zone.zone_id === line.zone_id)

		if (!zone) {
			line.zone_id = null
			line.zone_label = ''
		} else {
			line.zone_label = zone.zone_label
		}
		return line
	})

	return newQuoteLines
}

export const quoteLinesFn = {
	addNewQuoteLine,
	removeQuoteLine,
	updateQuoteLine,
	calculateQuoteLines,
	checkZones,
}
