import { useState, useCallback, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import Papa from 'papaparse' // Import the CSV parsing library
import { FormikProps } from 'formik'
import { IQuoteForm } from 'models/simple_quotes.model'
import { revertNumberFormat } from 'utilities'

interface Props {
	formik: FormikProps<IQuoteForm>
	field: string
	setFieldValue: (field: string, value: string) => void
	value: string
}

export const CreateCSVFile: React.FC<Props> = ({
	formik,
	field,
	setFieldValue,
	value,
}: Props) => {
	const [fileUrl, setFileUrl] = useState<string>(value || '')
	const [fileName, setFileName] = useState<string>('')
	const [fileLoading, setFileLoading] = useState<boolean>(false)

	const onDrop = useCallback(async (files: File[]) => {
		if (files?.length > 0 && files?.length < 2) {
			setFileLoading(true)
			const file = files[0]
			if (file.size <= 20 * 1024 * 1024) {
				const reader = new FileReader()

				reader.onload = (e) => {
					const csvText = e.target?.result as string
					console.log({ csvText })

					Papa.parse(csvText, {
						header: true,
						skipEmptyLines: true,
						complete: function (results: any) {
							const { extractedLines, uniqueSections } = processCSVData(
								results.data
							)
	
							const maxZones = uniqueSections.length
							formik.setFieldValue('max_zones', maxZones)

							const zones = uniqueSections?.map((section: any, index) => {
								return {
									id: null,
									zone_id: index + 1,
									zone_label: section,
								}
							})

							formik.setFieldValue('zones', zones)

							let quoteLines = []
							for (const extractedLine of extractedLines) {
								const zone = zones.find(
									(zone) => zone.zone_label === extractedLine.section
								)
								quoteLines.push({
									id: null,
									zone_id: zone?.zone_id,
									zone_label: zone?.zone_label,
									type: '',
									description: extractedLine.description,
									meters: Number(extractedLine.meters),
									calculation: extractedLine.calculation,
									labour:
										extractedLine.labour.trim() &&
										!extractedLine.labour.trim().includes('-')
											? revertNumberFormat(extractedLine.labour.trim())
											: 0,
									transport:
										extractedLine.transport.trim() &&
										!extractedLine.transport.trim().includes('-')
											? revertNumberFormat(extractedLine.transport.trim())
											: 0,
									scrim:
										extractedLine.scrim.trim() &&
										!extractedLine.scrim.trim().includes('-')
											? revertNumberFormat(extractedLine.scrim.trim())
											: 0,
									shrinkwrap:
										extractedLine.shrinkwrap.trim() &&
										!extractedLine.shrinkwrap.trim().includes('-')
											? revertNumberFormat(extractedLine.shrinkwrap.trim())
											: 0,
									hire:
										extractedLine.hire.trim() &&
										!extractedLine.hire.trim().includes('-')
											? revertNumberFormat(extractedLine.hire.trim())
											: 0,
									hire_duration: extractedLine.hireDuration
										? extractedLine.hireDuration.trim()
										: 1,
									labour_is_dirty: false,
									transport_is_dirty: false,
									scrim_is_dirty: false,
									shrinkwrap_is_dirty: false,
									hire_is_dirty: false,
									total: 0,
								})
							}

							formik.setFieldValue('quote_lines', quoteLines)
						},
					})
				}

				setFileLoading(false)
				reader.readAsText(file)
				setFileName(file.name)
			}
		} else {
			setFileLoading(false)
			alert('File size must be less than 20MB')
		}
	}, [])
	const processCSVData = (data: any[]) => {
		const sections = new Set<string>()

		const extractedLines: any[] = []
		data.forEach((row: any) => {
			const section = row['Section']
			sections.add(section)

			extractedLines.push({
				section: row['Section'],
				description: row['Description'],
				meters: row['Metres'],
				calculation: row['Type'],
				labour: row['Labour cost'],
				transport: row['Travel cost'],
				scrim: row['Scrim Cost'],
				shrinkwrap: row['Shrink cost'],
				hire: row['Hire Cost'],
				hireDuration: row['Hire Duration'],
				totalHire: row['Total Hire'],
			})
		})
		const uniqueSections = Array.from(sections)

		return { extractedLines, uniqueSections }
	}

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

	return (
		<div className="pb-4">
			<div className="px-2" {...getRootProps()}>
				<div className="mt-1 flex justify-center px-6 py-2 border-2 border-gray-300 border-dashed rounded-md">
					<div className="space-y-1 text-center">
						<div className="flex text-sm text-gray-600">
							<div className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500">
								<span>Upload a file</span>
								<input type="file" className="sr-only" {...getInputProps()} />
							</div>
							<p className="pl-1">
								{isDragActive ? 'Drop file here' : 'or drag and drop'}
							</p>
						</div>
					</div>
				</div>
			</div>
			{/* {fileName && <p className="mt-2">Uploaded file: {fileName}</p>} */}
			{fileLoading && <p>Loading...</p>}
		</div>
	)
}
