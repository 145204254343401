import React from 'react'
import { Dropdown, DateSelect, Input } from 'common'
import { IStaffRow } from 'models/staff.model'
import { CreateFile } from 'common'

interface Props {
	values: IStaffRow
	staff?: IStaffRow[] | undefined
	handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
	handleBlur: (e: React.FocusEvent<HTMLInputElement>) => void
	setFieldValue: (
		field: string,
		value: unknown,
		shouldValidate?: boolean | undefined
	) => void
	setFieldTouched: (
		field: string,
		touched?: boolean,
		shouldValidate?: boolean
	) => void
}

export const ScaffoldingCertificateOfCompetence: React.FC<Props> = ({
	values,
	staff,
  handleChange,
	handleBlur,
	setFieldValue,
	setFieldTouched,
}) => {
	const renderStaffList = () => {
		if (staff && staff?.length > 0) {
			return staff.map((item) => ({
				label: item.staff_name,
				value: item.staff_name || '',
			}))
		}
		return []
	}
	return (
		//
		<div>
			<h3 className="px-4 pt-2 text-lg font-semibold leading-5">
				Scaffolding Certificate of Competence
			</h3>
			<div>
        {/* <div className="flex items-center px-2">
          <Input
						title="Certificate Number"
						id="cert_num"
						type="text"
						handleChange={handleChange}
						handleBlur={handleBlur}
						value={values.cert_num}
						placeholder="Certificate Number"
					/>
				</div> */}
				<div className="flex items-center px-2">
					<DateSelect
						title="Issue Date"
						id="cert_issue_date"
						value={values.cert_issue_date}
						onChange={(id: string, date: string) => setFieldValue(id, date)}
					/>
					<DateSelect
						title="Expiry Date"
						id="cert_expiry_date"
						value={values.cert_expiry_date}
						onChange={(id: string, date: string) => setFieldValue(id, date)}
					/>
				</div>
				<div className="w-1/2 pl-2">
					<h4 className="text-md font-normal leading-5 px-4">Photo</h4>
					<CreateFile
						field="cert_photo"
						setFieldValue={setFieldValue}
						value={values.cert_photo}
					/>
				</div>
				{/* <div className="w-1/2 pl-2">
					<Dropdown
						label="Scaffolding Certificate of Competence Assessed By"
						id="scaff_cert_assessed_by"
						value={values.scaff_cert_assessed_by}
						onChangeVal="scaff_cert_assessed_by"
						onChange={setFieldValue}
						onBlur={setFieldTouched}
						options={renderStaffList()}
					/>
				</div> */}
			</div>
		</div>
	)
}
